<template>
  <v-card class="px-12" flat>
    <v-row>
      <v-col cols="12">
        <v-row justify="start">
          <v-col cols="auto">
            <span class="title">Cadastro de Filiados</span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-col>
      <v-col>
        <v-list>
          <draggable v-model="data.franchisees" :options="dragOptions" @end="updateOrder">
            <v-list-item v-for="(item, index) in data.franchisees" :key="index" :class="{ 'v-list-item--active': item.dragging }">
              <v-list-item-content>
                <v-card>
                  <v-row>
                    <v-col cols="1">
                      <v-row>
                        <v-col>
                          <v-sheet class="d-flex align-center" height="100">
                            <v-sheet class="ma-2 pa-2">
                              <span class="number-title">
                                {{ item.order }}
                              </span>

                              <v-icon class="drag-indicator mb-2" @mousedown.stop="startDragging(index)">mdi-drag</v-icon>
                            </v-sheet>
                          </v-sheet>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="11">
                      <v-row>
                        <v-col cols="3">
                          <span>
                            <strong>Nome</strong>
                          </span>
                          <br />
                          <span>
                            {{ item.name }}
                          </span>
                        </v-col>
                        <v-col cols="3">
                          <span>
                            <strong>WhatsApp</strong>
                          </span>
                          <br />
                          <span>
                            {{ item.whatsapp }}
                          </span>
                        </v-col>
                        <v-col cols="3">
                          <span>
                            <strong>Telefone</strong>
                          </span>
                          <br />
                          <span>
                            {{ item.phone }}
                          </span>
                        </v-col>
                        <v-col cols="auto">
                          <div :class="item.active ? 'active' : 'inactive'">
                            <div class="checkbox-container">
                              <v-row no-gutters>
                                <v-col cols="9" class="mt-1">
                                  <label class="checkbox-label">{{ item.active ? 'Mercado ativo' : 'Mercado inativo' }}</label>
                                </v-col>
                                <v-col>
                                  <mf-toggle v-model="item.active" color="#4caf50" hideDetails></mf-toggle>
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <span>
                            <strong>URL</strong>
                          </span>
                          <br />
                          <span>
                            {{ item.url }}
                          </span>
                        </v-col>
                        <v-col cols="3">
                          <span>
                            <strong>E-mail</strong>
                          </span>
                          <br />
                          <span>
                            {{ item.email }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-list-item-content>
            </v-list-item>
          </draggable>
        </v-list>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },
  props: {
    dataLandingPage: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: this.dataLandingPage,
      dragOptions: {
        animation: 200
      }
    }
  },
  watch: {
    data: {
      handler() {
        this.$emit('input', this.data)
      },
      deep: true
    },
    dataLandingPage: {
      handler() {
        this.data = this.dataLandingPage
      },
      deep: true
    }
  },
  methods: {
    startDragging(index) {
      this.data[index].dragging = true
    },
    updateOrder() {
      this.data.franchisees.forEach((item, index) => {
        item.order = index
      })
    }
  }
}
</script>

<style>
.drag-indicator {
  cursor: grab;
  margin-right: 16px;
}
.active {
  background-color: rgba(0, 255, 0, 0.2);
  border: 1px solid #4caf50;
  border-radius: 10px;
  padding: 10px;
  width: 184px;
}
.inactive {
  background-color: rgba(208, 212, 208, 0.2);
  border: 1px solid #a5acb4;
  border-radius: 10px;
  padding: 10px;
  width: 184px;
}
.checkbox-container {
  display: flex;
  align-items: center;
}
.number-title {
  font-family: Lato;
  font-size: 19px;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-align: left;
}
</style>
